<template>
  <div v-show="visible" class="wrapper">

    <div style="display: flex; justify-content: space-between; margin-bottom: 8px; margin-right: 18px">
      <h2>Ввод данных клиента</h2>
    </div>

    <div class="scrolling-table">
      <form ref="form" @submit.prevent="save">
        <div
          v-if="$parent.form.lang !== 'en' || $parent.form.type === 'pcr_antibody' || $parent.form.type === 'antibody'"
          class="grid grid-cols-3 gap-4"
        >
          <v-input
            v-if="$parent.analysis_id"
            v-model="$parent.form.surname_ru"
            :error="$parent.errors.surname_ru"
            placeholder="Фамилия"
            required
          />
          <v-select
            v-else
            v-model="$parent.form.surname_ru"
            :error="$parent.errors.surname_ru"
            :options="surnames_ru"
            :show-search="true"
            placeholder="Фамилия"
            required
          />
          <v-input
            v-model="$parent.form.name_ru"
            :error="$parent.errors.name_ru"
            placeholder="Имя"
            required
          />
          <v-input
            v-model="$parent.form.patronymic_ru"
            :error="$parent.errors.patronymic_ru"
            placeholder="Отчество"
          />
        </div>

        <div
          v-if="$parent.form.lang === 'en' || $parent.form.lang === 'ru_en'"
          class="grid grid-cols-3 gap-4"
        >
          <v-input
            v-if="$parent.analysis_id"
            v-model="$parent.form.surname_en"
            :error="$parent.errors.surname_en"
            placeholder="Фамилия (EN)"
            required
          />
          <v-select
            v-else
            v-model="$parent.form.surname_en"
            :error="$parent.errors.surname_en"
            :options="surnames_en"
            :show-search="true"
            placeholder="Фамилия (EN)"
            required
          />
          <v-input
            v-model="$parent.form.name_en"
            :error="$parent.errors.name_en"
            placeholder="Имя (EN)"
            required
          />
          <v-input
            v-model="$parent.form.patronymic_en"
            :error="$parent.errors.patronymic_en"
            placeholder="Отчество (EN)"
          />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <DatePicker
            v-model="$parent.form.birthdate"
            :error="$parent.errors.birthdate"
            placeholder="Дата рождения"
            required
          />
          <div style="display: flex; margin-top: 14px; margin-left: 14px">
            <VRadio v-model="$parent.form.gender" val="male">Мужской</VRadio>
            <VRadio v-model="$parent.form.gender" val="female">Женский</VRadio>
          </div>
        </div>

        <div
          v-if="$parent.form.lang !== 'en' || $parent.form.type === 'pcr_antibody' || $parent.form.type === 'antibody'"
          class="grid grid-cols-1 gap-4"
        >
          <v-input
            v-model="$parent.form.address_ru"
            :error="$parent.errors.address_ru"
            placeholder="Адрес"
          />
        </div>
        <div
          v-if="$parent.form.lang === 'en' || $parent.form.lang === 'ru_en'"
          class="grid grid-cols-1 gap-4"
        >
          <v-input
            v-model="$parent.form.address_en"
            :error="$parent.errors.address_en"
            placeholder="Адрес (EN)"
          />
        </div>

        <div
          v-if="$parent.form.lang !== 'en' || $parent.form.type === 'pcr_antibody' || $parent.form.type === 'antibody'"
          class="grid grid-cols-3 gap-4"
        >
          <v-select
            v-model="$parent.form.doc"
            :error="$parent.errors.doc"
            :options="docs"
            placeholder="Тип документа"
            required
            @input="selectDocRu"
          />
          <v-input
            v-model="$parent.form.series"
            :error="$parent.errors.series"
            placeholder="Серия документа"
            required
          />
          <v-input
            v-model="$parent.form.number"
            :error="$parent.errors.number"
            placeholder="Номер документа"
            required
          />
        </div>
        
        <div
          v-if="$parent.form.lang === 'en' || $parent.form.lang === 'ru_en'"
          class="grid grid-cols-3 gap-4"
        >
          <v-select
            v-model="$parent.form.doc_en"
            :error="$parent.errors.doc_en"
            :options="docs"
            placeholder="Тип документа (EN)"
            required
            @input="selectDocEn"
          />
          <v-input
            v-model="$parent.form.series_en"
            :error="$parent.errors.series_en"
            placeholder="Серия документа (EN)"
            required
          />
          <v-input
            v-model="$parent.form.number_en"
            :error="$parent.errors.number_en"
            placeholder="Номер документа (EN)"
            required
          />
        </div>

        <div
          v-if="$parent.form.lang !== 'en'"
          class="grid grid-cols-1 gap-4"
        >
          <v-input
            v-model="$parent.form.snils"
            :error="$parent.errors.snils"
            placeholder="СНИЛС"
          />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <v-input
            v-model="$parent.form.phone"
            :error="$parent.errors.phone"
            placeholder="Телефон"
            required
          />
          <v-input
            v-model="$parent.form.email"
            :error="$parent.errors.email"
            placeholder="Email"
            type="email"
          />
        </div>

        <div
          v-if="$parent.form.type !== 'antibody'"
          class="grid gap-4"
        >
          <div style="display: flex; padding-bottom: 15px;">
            <div style="margin-right: 30px;">
              <b>Результат ПЦР</b>
            </div>
            <VRadio v-model="$parent.form.pcr" val="0">Отрицательный</VRadio>
            <VRadio v-model="$parent.form.pcr" val="1">Положительный</VRadio>
          </div>
        </div>

        <div
          v-if="$parent.form.antibodyType"
          class="grid gap-4"
          :class="$parent.form.antibodyType === 'g' || $parent.form.antibodyType === 'g_m' ? 'grid-cols-3' : 'grid-cols-2'"
        >
          <v-input
            v-if="$parent.form.antibodyType === 'g' || $parent.form.antibodyType === 'g_m'"
            v-model="$parent.form.antibodyTypeG"
            :disabled="$parent.form.antibodyType === 'm' || $parent.form.antibodyType === 'gs'"
            :error="$parent.errors.antibodyTypeG"
            placeholder="lgG"
            required
          />
          <v-input
            v-model="$parent.form.antibodyTypeM"
            :disabled="$parent.form.antibodyType === 'g' || $parent.form.antibodyType === 'gs'"
            :error="$parent.errors.antibodyTypeM"
            placeholder="lgM"
            required
          />
          <v-input
            v-model="$parent.form.antibodyTypeGS"
            :disabled="$parent.form.antibodyType !== 'gs'"
            :error="$parent.errors.antibodyTypeGS"
            placeholder="lgG (капсидный белок)"
            required
          />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <DatePicker
            v-model="$parent.form.order_date"
            :error="$parent.errors.order_date"
            format="DD.MM.YYYY HH:mm"
            placeholder="Дата заказа"
            showTime
          />
          <DatePicker
            v-model="$parent.form.issue_date"
            :error="$parent.errors.issue_date"
            format="DD.MM.YYYY HH:mm"
            placeholder="Дата выдачи"
            showTime
          />
        </div>

        <button ref="submit_button" hidden type="submit"/>
      </form>
    </div>

    <next-button @nextClick="$refs.submit_button.click()" @prevClick="prevClick">Далее</next-button>
  </div>
</template>

<script>
import NextButton from "@/views/analysis/update/components/NextButton";
import VInput from "@/components/ui/VInput";
import VSelect from "@/components/ui/VSelect";
import VRadio from "@/components/ui/VRadio";
import DatePicker from "@/components/ui/DatePicker"
import {list} from "@/api/client/listOnlySurname"

export default {
  name: "CustomerData",
  props: {
    visible: Boolean,
    form: Object
  },

  components: {
    NextButton,
    VInput,
    DatePicker,
    VSelect,
    VRadio
  },

  data() {
    return {
      list,
      surnames_ru: [],
      surnames_en: [],
      dataSource: [],
      docs: [
        {value: 'passport', text: 'Паспорт гражданина РФ'},
        {value: 'international_passport', text: 'Заграничный паспорт'},
        {value: 'birth_certificate', text: 'Свидетельство о рождении'},
        {value: 'residence_permit', text: 'Вид на жительство'},
        {value: 'foreign_citizen_passport', text: 'Паспорт иностранного гражданина'},
        {value: 'other', text: 'Иное'},
      ],
    }
  },

  created() {
    this.getClients()
  },

  methods: {
    prevClick() {
      this.$parent.currentStep = 0
    },

    getClients() {
      list({params: {lang: 'ru'}}).then(res => {
        this.surnames_ru = res.data.data;
      })
      list({params: {lang: 'en'}}).then(res => {
        this.surnames_en = res.data.data;
      })
    },

    save() {
      this.$parent.stepsStatus.customerData = ''
      this.$parent.form.step = 2
      this.$parent.form.save = false
      this.$parent.save()
    },
  
    selectDocRu(value) {
      this.$parent.documents.forEach(document => {
        if (value === document.type.value) {
          this.$parent.form.series = document.series
          this.$parent.form.number = document.number
        }
      })
    },
  
    selectDocEn(value) {
      this.$parent.documents.forEach(document => {
        if (value === document.type.value) {
          this.$parent.form.series_en = document.series
          this.$parent.form.number_en = document.number
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 100%;

  .scrolling-table {
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 10px;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #0059A3;
      border-radius: 3px;
    }
  }
}
</style>