<template>
  <div v-show="visible" class="wrapper">
    <Review :hide-back="true" :analysis="$parent.form"/>

    <div style="display: flex; justify-content: space-between">
      <a-checkbox v-if="$parent.form.lang !== 'en' || $parent.form.type === 'pcr_antibody' || $parent.form.type === 'antibody'" v-model="$parent.form.gosuslugi">
        Отправить в Госуслуги
      </a-checkbox>

      <v-button @click.native="save(true)">Сформировать</v-button>
    </div>

    <next-button @nextClick="save(false)" @prevClick="prevClick">{{$parent.analysis_id ? 'Сохранить' : 'Зарегистрировать'}}</next-button>
  </div>
</template>

<script>
import NextButton from "@/views/analysis/update/components/NextButton";
import Review from "@/views/analysis/update/components/Review";
import VButton from "@/components/ui/VButton";
import ACheckbox from "ant-design-vue/lib/checkbox";

export default {
  components: {NextButton, Review, VButton, ACheckbox},
  name: "Completion",
  props: {
    visible: Boolean,
  },
  methods: {
    prevClick() {
      this.$parent.currentStep = 1
    },

    save(isSave) {
      this.$parent.form.save = isSave
      this.$parent.form.step = 3
      this.$parent.save()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}
</style>