<template>
  <div v-show="visible" class="wrapper">
    <h2>Результат ПЦР</h2>
    <v-select v-model="$parent.form.lang"
              :error="$parent.errors.lang"
              :options="langOptions"
              placeholder="Выберите документ ПЦР"/>

    <v-select v-if="$parent.form.lang"
              v-model="$parent.form.pcr_doctor_id"
              :error="$parent.errors.pcr_doctor_id"
              :options="doctors"
              :show-search="true"
              placeholder="Выберите врача"/>

    <v-select v-if="$parent.form.lang === 'ru_en' || $parent.form.lang === 'en'"
              v-model="$parent.form.pcr_cert_doctor_id"
              :error="$parent.errors.pcr_cert_doctor_id"
              :options="doctors"
              :show-search="true"
              placeholder="Выберите врача (сертификат)"/>

    <h2>Результат Антитела</h2>
    <v-select v-model="$parent.form.antibodyType"
              :error="$parent.errors.antibodyType"
              :options="filteredAntibodiesOptions"
              placeholder="Выберите документ Антитела"/>

    <v-select v-if="$parent.form.antibodyType"
              v-model="$parent.form.antibody_doctor_id"
              :error="$parent.errors.antibody_doctor_id"
              :options="doctors"
              :show-search="true"
              placeholder="Выберите врача"/>

    <next-button :disabled="!$parent.form.type" hidePrevBtn @nextClick="nextClick">
      Далее
    </next-button>
  </div>
</template>

<script>
import VSelect from "@/components/ui/VSelect";
import {list} from '@/api/doctor/list'
import _ from 'lodash'
import NextButton from "@/views/analysis/update/components/NextButton";

export default {
  components: {VSelect, NextButton},
  name: "ResultTemplate",
  props: {
    visible: Boolean,
  },
  data() {
    return {
      antibodiesOptions: [
        {value: 'm', text: 'M'},
        {value: 'g', text: 'G'},
        {value: 'g_m', text: 'G + M'},
        {value: 'gs', text: 'G (капсидный белок)'},
      ],
      langOptions: [
        {value: 'ru', text: 'rus'},
        {value: 'en', text: 'en'},
        {value: 'ru_en', text: 'rus+en'},
      ],

      doctors: []
    }
  },

  computed: {
    filteredAntibodiesOptions() {
      return this.antibodiesOptions.filter(item => ['m', 'gs', this.$parent.form.antibodyType].indexOf(item.value) !== -1)
    }
  },

  created() {
    this.getDoctors()
  },

  methods: {
    getDoctors() {
      list().then(res => {
        _.map(res.data.data, val => {
          this.doctors.push({
            value: val.id,
            text: val.value_ru
          })
        })
      })
    },

    nextClick() {
      this.$parent.form.step = 1
      this.$parent.save()
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 100%;

  h2 {
    margin-bottom: 8px;
  }
}
</style>