import api from '../api';

function getPdf(id) {
  return api.get(`/api/analysis/${id}/pdf`, {
    responseType: 'blob',
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'
    link.click();
    // window.URL.revokeObjectURL(url);
  });
}

export {getPdf};
