<template>
  <div class="button_wrapper">
    <div v-if="!hidePrevBtn">
      <span @click="prevClick()">Вернуться назад</span>
    </div>
    <v-button :disabled="disabled" @click.native="nextClick()">
      <slot></slot>
    </v-button>
  </div>
</template>

<script>
import VButton from "@/components/ui/VButton";

export default {
  name: "NextButton",
  components: {
    VButton,
  },

  props: {
    disabled: Boolean,
    hidePrevBtn: Boolean
  },

  methods: {
    nextClick() {
      this.$emit('nextClick', true)
    },

    prevClick() {
      this.$emit('prevClick', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.button_wrapper {
  text-align: center;
  margin-top: auto;

  > div {
    color: #B1BACB;
    font-size: 14px;
    margin-bottom: 24px;
    cursor: pointer
  }
}
</style>