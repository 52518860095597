<template>
  <div class="content">
    <Title class="mt-2" justify="center">{{ analysis_id ? 'РЕДАКТИРОВАНИЕ' : 'СОЗДАНИЕ' }} РЕЗУЛЬТАТА АНАЛИЗА</Title>

    <steps :current-step="currentStep" :status="status" :stepsStatus="stepsStatus"/>
    <div class="flex justify-center" style="height: 100%;">
      <div style="width: 60%">
        <!--    Шаблон анализа-->
        <result-template :visible="currentStep === 0"/>
        <!--    Данные клиента-->
        <customer-data :visible="currentStep === 1"/>
        <!--    Завершение-->
        <completion :visible="currentStep === 2"/>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/ui/Title'
import CustomerData from "@/views/analysis/update/components/CustomerData";
import ResultTemplate from "@/views/analysis/update/components/ResultTemplate";
import Completion from "@/views/analysis/update/components/Completion";
import Steps from "./components/Steps"
import moment from "moment";
import {create} from "@/api/analysis/create";
import {update} from "@/api/analysis/update";
import {getById} from "@/api/analysis/getById";
import {getPdf} from "@/api/analysis/getPdf";
import {getById as getClient} from "@/api/client/getById"
import _ from "lodash";

export default {
  name: "Create",
  components: {
    Title,
    CustomerData,
    ResultTemplate,
    Completion,
    Steps
  },

  data() {
    return {
      analysis_id: null, //id из роута если редактирование
      currentStep: 0,
      stepsStatus: {
        customerData: ''
      },
      status: '',
      errors: {},
      documents: [],
      form: {
        lang: null,
        type: null,
        pcr: '0',
        antibodyType: null,
        antibodyTypeG: null,
        antibodyTypeM: null,
        antibodyTypeGS: null,
        order_date: moment(),
        issue_date: null,

        surname_ru: null,
        name_ru: null,
        patronymic_ru: null,
        address_ru: null,
        email: null,

        surname_en: null,
        name_en: null,
        patronymic_en: null,
        address_en: null,

        gender: 'male',
        birthdate: null,
        phone: null,

        snils: null,
        doc: null,
        series: null,
        number: null,
        doc_en: null,
        series_en: null,
        number_en: null,
        gosuslugi: false,

        pcr_doctor_id: null,
        pcr_cert_doctor_id: null,
        antibody_doctor_id: null,
        step: null,
      },
    }
  },

  watch: {
    form: {
      handler() {
        if (this.form.antibodyType && this.form.lang) {
          this.form.type = 'pcr_antibody'
        } else if (this.form.antibodyType) {
          this.form.type = 'antibody'
        } else if (this.form.lang) {
          this.form.type = 'pcr'
        }
      },
      deep: true,
    },

    'form.surname_ru'(val) {
      this.fetchClient(val)
    },

    'form.surname_en'(val) {
      this.fetchClient(val)
    },
  },
  methods: {
    save() {
      let form = _.omitBy(_.cloneDeep(this.form), _.isNil);
      const query = this.analysis_id ? update(this.analysis_id, form) : create(form)
      this.errors = {}

      query
          .then((res) => {
            if (this.form.step === 3) {
              //если последний шаг вызываем метод для пдф
              if (this.form.save) {
                let analysisId = res.data.data.id
                getPdf(analysisId).then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                  const link = document.createElement('a');
                  link.href = url;
                  link.target = '_blank'
                  link.click();
                  window.URL.revokeObjectURL(url);
                })

                if (this.$route.name === 'AnalysisCreate') {
                  this.analysis_id = res.data.data.id;
                  this.$router.push(`/analysis/${res.data.data.id}/edit?step=3`);
                }
              } else {
                this.$router.push('/analysis')
              }

              this.getNotify('success', this.form.save ? 'Сформировано' : 'Сохранено');
            } else {
              this.currentStep = this.currentStep + 1
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              this.errors = error.response.data.errors

              if (this.errors.issue_date) {
                this.stepsStatus.customerData = 'error';
                this.getNotify('error', this.errors.issue_date[0]);
              }
            }
          })
    },

    getAnalysis(id) {
      getById(id).then((res) => {
        let data = res.data.data

        //данные для 1 шага
        this.form.id = data.id
        this.form.type = data.type.value
        this.form.lang = data.type.value !== 'antibody' ? data.lang.value : null

        _.map(data.doctors, val => {
          if (val.type === 'pcr') {
            this.form.pcr_doctor_id = val.id
          }
          if (val.type === 'pcr_cert') {
            this.form.pcr_cert_doctor_id = val.id
          }
          if (val.type === 'antibody') {
            this.form.antibody_doctor_id = val.id
          }
        })

        //данные для 2 шага
        this.form.phone = data.client.phone
        this.form.email = data.client.email
        this.form.gender = data.client.gender.value

        this.form.name_ru = data.client.attributes.ru.name
        this.form.surname_ru = data.client.attributes.ru.surname
        this.form.patronymic_ru = data.client.attributes.ru.patronymic
        this.form.address_ru = data.client.attributes.ru.address

        this.form.client_id = data.client.id
        this.form.name_en = data.client.attributes.en.name
        this.form.surname_en = data.client.attributes.en.surname
        this.form.patronymic_en = data.client.attributes.en.patronymic
        this.form.address_en = data.client.attributes.en.address

        this.form.order_date = data.order_date
        this.form.issue_date = data.issue_date
        this.form.birthdate = data.client.birthdate
        this.form.doc = data.doc
        this.form.doc_en = data.doc_en

        this.form.pcr = data.pcr
        this.form.antibodyTypeG = data.antibody_g
        this.form.antibodyTypeM = data.antibody_m
        this.form.antibodyTypeGS = data.antibody_gs

        if (data.antibody_g && data.antibody_m) {
          this.form.antibodyType = 'g_m'
        } else if (data.antibody_g) {
          this.form.antibodyType = 'g'
        } else if (data.antibody_m) {
          this.form.antibodyType = 'm'
        } else if (data.antibody_gs) {
          this.form.antibodyType = 'gs'
        }

        _.map(data.client.documents, val => {
          switch (val.type.value) {
            case 'snils':
              this.form.snils = val.number;
              break;
            case this.form.doc:
              this.form.series = val.series
              this.form.number = val.number
              // falls through
            case this.form.doc_en:
              this.form.series_en = val.series
              this.form.number_en = val.number
              break;
          }
        })

        if (
          this.form.type === 'pcr' &&
          this.form.lang === 'en'
        ) {
          this.form.doc = null
          this.form.series = null
          this.form.number = null
        }

        if (this.form.lang === 'ru') {
          this.form.doc_en = null
          this.form.series_en = null
          this.form.number_en = null
        }
        
        this.documents = data.client.documents

        this.currentStep = 1

        //Если нажал сформировать чтоб не было дубля
        if (this.$route.query.step === '3') {
          this.currentStep = 2
        }

        this.form.gosuslugi = data.gosuslugi
      })
    },

    fetchClient(id) {
      //Если в селекте id клиента, а не строка, берем его данные
      if (Number.isInteger(id)) {
        getClient(id).then((res) => {
          let client = res.data.data
          this.form.gender = client.gender.value
          this.form.birthdate = client.birthdate
          this.form.phone = client.phone
          this.form.email = client.email

          this.form.name_ru = client.attributes.ru.name
          this.form.surname_ru = client.attributes.ru.surname
          this.form.patronymic_ru = client.attributes.ru.patronymic
          this.form.address_ru = client.attributes.ru.address

          this.form.name_en = client.attributes.en.name
          this.form.surname_en = client.attributes.en.surname
          this.form.patronymic_en = client.attributes.en.patronymic
          this.form.address_en = client.attributes.en.address

          _.map(client.documents, val => {
            if (val.type.value === 'snils') {
              this.form.snils = val.number
            } else {
              this.form.series = val.series
              this.form.number = val.number
              this.form.doc = val.type.value
              
              this.form.series_en = val.series
              this.form.number_en = val.number
              this.form.doc_en = val.type.value
            }
          })
  
          this.documents = client.documents
        })
      }
    },
  },

  created() {
    this.analysis_id = this.$route.params.id ? this.$route.params.id : null
    this.analysis_id && this.getAnalysis(this.analysis_id)
  },
}
</script>