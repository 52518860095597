<template>
  <div class="steps-wrapper">
    <a-steps :current="currentStep" class="steps" labelPlacement="vertical" size="small">
      <a-step title="Шаблон результата"/>
      <a-step title="Данные клиента" :status="stepsStatus.customerData"/>
      <a-step title="Завершение"/>
    </a-steps>
  </div>
</template>

<script>
import Vue from 'vue'
import Steps from 'ant-design-vue/lib/steps';

Vue.use(Steps);

export default {
  name: "Steps",
  props: {
    currentStep: Number,
    stepsStatus: Object,
  }
}
</script>

<style lang="scss" scoped>
.steps-wrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.steps::v-deep {
  width: 500px;

  .ant-steps-item-title {
    min-width: 135px;
    margin-left: -6px;
  }

  .ant-steps-icon {
    margin-top: -2px;

    svg {
      margin-top: -5px;
    }
  }
}
</style>